<template>
  <div class="g-box g-role-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-row :gutter="20">
        <el-col :span="10">
          中文
          <el-form
            :model="deviceInfoForm"
            class="demo-form-inline"
            size="medium"
            :rules="rules"
            ref="deviceInfoForm"
            label-width="180px"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="标题" prop="jobTitle">
                  <el-input
                    v-model="deviceInfoForm.jobTitle"
                    :readonly="isModeInfo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="副标题" prop="subTitle">
                  <el-input
                    v-model="deviceInfoForm.subTitle"
                    :readonly="isModeInfo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item prop="bannerImgUrl" :label="'详情banner图：'">
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadSrc"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <el-image
                      v-if="deviceInfoForm.bannerImgUrl"
                      :src="deviceInfoForm.bannerImgUrl"
                      :fit="'contain'"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col> -->
              <el-col :span="20">
                <el-form-item prop="content" :label="$t('add.文章内容') + '：'">
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="10">
          English
          <el-form
            :model="deviceInfoFormLang"
            class="demo-form-inline"
            size="medium"
            :rules="{ ...rules }"
            ref="deviceInfoFormLang"
            label-width="180px"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="Title" prop="jobTitle">
                  <el-input
                    v-model="deviceInfoFormLang.jobTitle"
                    :readonly="isModeInfo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Sub Title" prop="subTitle">
                  <el-input
                    v-model="deviceInfoFormLang.subTitle"
                    :readonly="isModeInfo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="content" :label="'Article Content' + '：'">
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-tabs type="card" value="zh">
            <el-tab-pane label="中文" name="zh">
              <quill-editor
                ref="myQuill"
                :placeholder="$t('global.请输入')"
                v-model="deviceInfoForm.content"
                :options="editorOption"
              />
            </el-tab-pane>
            <el-tab-pane label="English" name="en"
              ><quill-editor
                ref="myQuillLang"
                :placeholder="$t('global.请输入')"
                v-model="deviceInfoFormLang.content"
                :options="editorOption"
            /></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn s-submit"
        size="medium"
        type="primary"
        @click="submitForm('deviceInfoForm')"
        v-if="!isModeInfo"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="medium"
        v-if="isModeInfo"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="medium"
        v-else
        @click="$router.go(-1)"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Store from "@/store";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { deepFn } from '@/tool'
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import Video from "@/tool/quill-video";
Quill.register(Video, true);
Quill.register('modules/ImageExtend', ImageExtend)
export default {
  components: {
    quillEditor
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      // 富文本框参数设置
      editorOption: {
        modules: {
          // ImageResize: {},
          ImageExtend: {
            loading: true,
            name: 'file',
            action: this.config.uploadUrl,
            response: (res) => {
              return res.data.url
            },
            headers: (xhr) => {
              xhr.setRequestHeader("X-Auth-User", this.$store.state.user.loginName);
              xhr.setRequestHeader("X-Auth-Token", this.$store.state.user.token);
            },
          },
          toolbar: {
            container: container,
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      deviceInfoForm: {
      },
      deviceInfoFormLang: {},
      headers: { "X-Auth-Token": Store.state.user.token },
      uploadSrc: this.config.uploadUrl,
      loading: true,
      rules: {
        langType: [
          { required: true, message: '请选择语言', trigger: 'blur' },
        ],
        jobTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        subTitle: [
          { required: true, message: '请输入副标题', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        // bannerImgUrl: [
        //   { required: true, message: '请上传banner图', trigger: 'blur' },
        // ]
      }
    }
  },
  computed: {
    isModeAdd () {
      return !this.$route.params.id
    },
    isModeEdit () {
      return !!this.$route.params.id
    },
    isModeInfo () {
      return false
    },
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  mounted () {
    this.initPaseImgUpload()
    this.initPaseImgUploadLang()
  },
  methods: {
    init () {
      if (this.isModeEdit || this.isModeInfo) {
        this.getInfo()
      }
      if (this.isModeAdd) {
        this.loading = false
      }
    },
    async getInfo () {
      try {
        const res = await api.joinUs.findById({ id: this.$route.params.id, associatedId: this.$route.params.associatedId })
        this.deviceInfoForm = { ...(res.data[0] || {}) }
        this.deviceInfoFormLang = { ...(res.data[1] || {}) }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async beforeAvatarUpload (file) {
      const isImg = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isImg) {
        return this.$message.error(this.$t('add.imgType'));
      }
      if (isImg) {
        this.loadingImg = true
      }
      return isImg
    },
    submitForm (formName) {
      this.$refs.deviceInfoForm.validate(async valid => {
        this.$refs.deviceInfoFormLang.validate(async validLang => {
          if (valid && validLang) {
            const query = [{ ...this.deviceInfoForm, langType: 1 }, { ...this.deviceInfoFormLang, bannerImgUrl: this.deviceInfoForm.bannerImgUrl, langType: 2 }]
            console.log(query)
            try {
              this.loading = true
              const res = await api.joinUs[this.isModeAdd ? 'save' : 'update'](query)
              this.$message.success('操作成功')
              this.$router.back()
              this.loading = false
            } catch (error) {
              this.loading = false
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.ql-container {
  min-height: 300px;
}
</style>
